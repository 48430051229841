import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../assets/scss/main.scss"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Marco Mazzocchi - Blog" />
        <div className="single-post pb-md-10 mt-5 pt-2">
          <div className="bg-f--primary">
            <div className="container">
              <div className="row justify-content-md-center d-flex">
                <div className="col-lg-8">
                  <div className="post__comments" id="post-comments">
                    <h1 className="mb-5">Blog</h1>
                    {posts.map(post => {
                      const { excerpt, timeToRead } = post.node
                      const { slug } = post.node.fields
                      const {
                        title,
                        description,
                        date,
                        cover,
                        category,
                      } = post.node.frontmatter

                      return (
                        <div className="post__comment" key={slug}>
                          <Link to={slug}>
                            <img
                              src={cover}
                              alt={title}
                              className="comment__img"
                            />
                          </Link>
                          <div className="comment__text">
                            <Link
                              to={slug}
                              className="btn btn-outline-primary btn-reply"
                            >
                              Leggi
                            </Link>
                            <div className="comment__author">
                              <h5>
                                <Link to={slug}>{title}</Link>
                              </h5>
                            </div>
                      <div className="comment__date">{date} - <TimeToRead value={timeToRead} /></div>
                            <p className="comment__the-comment">
                              {description || excerpt}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const TimeToRead = ({value}) => {
  
  const cup = <i className="icon lni-coffee-cup" />;
  const icons = [];
  let rate = 5;
  if(value < 5) {
    rate = 1;
  }
  else if(value < 10) {
    rate = 2;
  }
  else if (value < 20) {
    rate = 3;
  }
  else if (value < 40) {
    rate = 4;
  }

  for(let i = 1; i <= rate; i++) { icons.push(cup) }
  return (
  <span className="time-to-read">
    {icons} {value} minuti di lettura
    </span>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 100
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "dddd, DD MMMM YYYY", locale: "it")
            title
            description
            category
            cover
          }
          timeToRead
        }
      }
    }
  }
`
